import React , { useEffect } from 'react';
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import Form from "../components/form"
import CTA from "../components/cta"
function getUrlVars(){
  var vars = [], hash;
  if(typeof window !== 'undefined'){
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
      for(var i = 0; i < hashes.length; i++)
      {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
      }
  }
  return vars;
}
function getUrlParams() {
  if (typeof window !== 'undefined') {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    return params;
  }
  return {};
}

const urlParams = getUrlParams();
export const query = graphql`
    query errorQuery{
        sanityCompanyInfo {
            companyname
            emptypage{
              asset{
                fluid(maxWidth: 1920){
                  ...GatsbySanityImageFluid
                }
              }
            }
            primarycolor{
                hex
            }
            secondarycolor{
                hex
            }
            accentcolor{
                hex
            }
        }
    }

`


const NotFoundPage = ({data}) => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());


    // Update the links with URL parameters
    document.querySelectorAll('.serviceLink').forEach((link) => {
    const serviceSlug = link.getAttribute('data-service-slug');
    const modifiedLink = `${serviceSlug}?${Object.entries(urlParams).map(([key, value]) => `${key}=${value}`).join('&')}`;
    link.href = modifiedLink;
    });
    
}, []);

return (
  <Layout>
    <SEO title="404: Not found" />
    <Form />
    <BackgroundImage
      style={{
        height: "100%",
        backgroundPosition: "center"
      }}
      fluid={data.sanityCompanyInfo.emptypage.asset.fluid}>
        <div className="pageNotFound-bg" style={{
              backgroundColor: data.sanityCompanyInfo.primarycolor.hex,
              opacity: "0.6"
          }}></div>
    <section id="pageNotFound">
      <div className="pageNotFound-inner">
        <h1>We could not find that one</h1>
        <Link to="/" className="serviceLink" data-service-slug={`${"/"}`} style={{backgroundColor: data.sanityCompanyInfo.accentcolor.hex}}>Go back home</Link>
      </div>
    </section>
    </BackgroundImage>

  <CTA />
  </Layout>
)
}

export default NotFoundPage
